.slider {
  height: 100vh;
  max-height: 850px;

  :global {
    .carousel-inner,
    .carousel-inner .carousel-item {
      height: 100%;
    }
  }
}
