.productImage {
    height: 100%;
    min-height: 200px;
    background-size: cover;
    background-position: center;
    border-radius: 0;
}
.swiperContainer {
    height: 100%;
    width: 100%;
    overflow: initial;
}
:global {
    .swiper-slide {
        text-align: center;
        margin: auto;
        transition: 0.5s ease all;
        transform: scale(0.4);
        transform-origin: top;
        opacity: 0;
        &.swiper-slide-prev, &.swiper-slide-next {
            transform: scale(0.7);
            opacity: 0.7;
        }
        &.swiper-slide-active {
            transform: scale(1);
            opacity: 1;
        }
     }
}