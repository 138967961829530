@import "~assets/scss/settings";

.nav {
  flex-direction: row !important;
  position: absolute;
  right: 0;

  svg {
    width: 1.2em;
    height: 1.2em;

    @include media-breakpoint-up(sm) {
      width: 1.5em;
      height: 1.5em;
    }
  }
}
.productsSliderContainer {
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 15px;
}

.homeNavbar {
  background-color: transparent;
  position: absolute;
  width: 100%;
  z-index: 1099;
}
