.project {
  background-position: center;
  background-size: cover;
  padding: 0;
  border-radius: 0 !important;

  &:after {
    content: " ";
    background: #000;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: 0.3s ease opacity;
  }

  &:hover {
    &:after {
      opacity: 0.5;
    }
  }
}
