@import "~assets/scss/settings";

.video:after,
.carouselItem:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.carouselItem:after {
  z-index: -2;
  background: $black;
}

:global {
  .carousel-control-prev,
  .carousel-control-next {
    width: 11% !important;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 30px !important;
    height: 30px !important;
  }

  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3Csvg stroke='%23fff' fill='none' stroke-width='2' viewBox='0 0 24 24' stroke-linecap='round' stroke-linejoin='round' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E") !important;
  }

  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3Csvg stroke='%23fff' fill='none' stroke-width='2' viewBox='0 0 24 24' stroke-linecap='round' stroke-linejoin='round' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") !important;
  }
}
